import React from 'react';
import '../assets/sass/fontawesome/css/fontawesome.css'
import Layout from '../components/Layout';
import product1 from '../assets/images/products-01.jpg';
import product2 from '../assets/images/products-02.jpg';
import product3 from '../assets/images/products-03.jpg';
import product4 from '../assets/images/products-04.jpg';

// import intro from '../assets/images/intro.jpg';
const IndexPage = () => (
  <Layout activeLink="home">



    <section>
      <div className="container">
        
        <div className="row">
          
          {/* Birthday */}
             <div className="col-sm card hvr-shrink">  
                <div class="wrapper">
                  <img
                    className="product-item-img index-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0 image icon"
                    src={product1}
                    alt=""
                  />
               </div>
              <div class="card-img-overlay">
                <h1 class="index card-title">Birthday</h1>
                <h6 class="index card-title">Kids | Adult</h6>
                <a href="/birthday/" class="stretched-link"> </a>
                {/* <h6 class="card-subtitle mb-2 text-muted"></h6> */}
                {/* <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p> */}
                {/* <a href="http://localhost:8000/contact" className="card-link">Card link</a> */}
                {/* <a href="http://localhost:8000/contact" className="card-link">Another link</a> */}
              </div>
            </div>
          
          {/* Cupcakes */}
          <div className="col-sm card hvr-shrink">
            <div class="wrapper">
              <img
                className="product-item-img index-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0"
                src={product2}
                alt=""
              />
            </div>
            <div class="card-img-overlay">
              <h1 class="index card-title">Cupcakes</h1>
              <a href="/cupcakes/" class="stretched-link"> </a>
              {/* <h6 class="card-subtitle mb-2 text-muted">Card subtitle</h6>
              <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
              <a href="http://localhost:8000/contact" className="card-link">Card link</a>
              <a href="http://localhost:8000/contact" className="card-link">Another link</a> */}
            </div>
          </div>
    
        </div>

      </div>
    </section> 

    <section>
      <div className="container">
        
        <div className="row">
          
          {/* Cupcakes */}
          <div className="col-sm card hvr-shrink">
            <div class="wrapper">
              <img
                className="product-item-img index-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0"
                src={product3}
                alt=""
              />
            </div>
            <div class="card-img-overlay">
              <h1 class="index card-title">Any Occasion</h1>
              <a href="/anyoccasion/" class="stretched-link"> </a>
            </div>
          </div>
          
          {/* Flavour & Servings */}
          <div className="col-sm card hvr-shrink">
            <div class="wrapper">
              <img
                className="product-item-img index-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0"
                src={product4}
                alt=""
              />
            </div>
            <div class="card-img-overlay">
              <h1 class="index card-title">Flavour & Price Guide</h1>
              <a href="/flavours/" class="stretched-link"> </a>
              {/* <h6 class="card-subtitle mb-2 text-muted">Card subtitle</h6>
              <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
              <a href="http://localhost:8000/contact" class="card-link">Card link</a>
              <a href="http://localhost:8000/contact" class="card-link">Another link</a> */}
            </div>
          </div>
        </div>

      </div>
    </section> 
 

    <section className="page-section cta">
      <div className="container">
        <div className="row">
          <div className="col-xl-9 mx-auto">
            <div className="text-center rounded">
              <section>
                <header>
                  {/* <h3>Table</h3> */}
                </header>
                <div className="table-wrapper">
                  <table className="default">
                    <thead>
                      <tr>
                        <th><h1><a href="https://www.facebook.com/Designer-sweet-treats-144017559724157/?tn-str=k*F" target="_blank" rel="noopener noreferrer"><i class="fab fa-facebook-square social"></i></a></h1></th>
                        <th><h1><a href="https://www.instagram.com/designer_sweet_treats/?hl=en" target="_blank" rel="noopener noreferrer"><i class="fab fa-instagram social"></i></a></h1></th>
                        <th><h1><a href="mailto:jeeranan@designersweettreats.net?subject=New%20Enquiry&body=Please%20tell%20us%20the%20following%20information%3A%0D%0A%0D%0AName%3A%20%0D%0AEvent%20Date%3A%20%0D%0AFlavours%3A%0D%0ANumber%20of%20Servings%3A%0D%0ACake%20Design%3A%0D%0AContact%20Number%3A%0D%0A%0D%0AIf%20possible%20please%20also%20attach%20a%20sketch%20or%20photo%20of%20the%20cake%20you%20would%20like." target="_top"><i class="far fa-envelope social"></i></a></h1></th>
                      </tr>
                    </thead>
                  </table>
                </div>
              </section>

            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);

export default IndexPage;
